import React, { useEffect } from "react";

const Alert = ({ message, onClose, duration = 5000 }) => {
    useEffect(() => {
        // Fermeture automatique après la durée spécifiée
        const timer = setTimeout(onClose, duration);
        return () => clearTimeout(timer);  // Nettoyage du timer lorsque le composant se démonte
    }, [onClose, duration]);

    return (
        <div className="animate-fade-in-out bg-red-500 text-white px-6 py-4 mb-4 rounded-lg shadow-lg flex items-center space-x-4 transition-all duration-500 transform">
            <span>{message}</span>
            <button
                onClick={onClose}
                className="text-white font-bold px-2 py-1 rounded bg-red-700 hover:bg-red-800 transition-colors"
            >
                X
            </button>
        </div>
    );
};

export default Alert;
