import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { pdf, Document, Page, Text, View, StyleSheet, Font } from '@react-pdf/renderer';
import Alert from './Alert';
import questions from '../assets/data/questions.json';
import axios from 'axios';

import robotoRegular from '../assets/fonts/Roboto-Regular.ttf';
import robotoBold from '../assets/fonts/Roboto-Bold.ttf';

Font.register({
    family: 'Roboto',
    fonts: [
        { src: robotoRegular, fontWeight: 'normal' },
        { src: robotoBold, fontWeight: 'bold' }
    ]
});

const fetchMarketPrices = async () => {
    try {
        return {
            siteType: { vitrine: 800, ecommerce: 1500, webapp: 2000 },
            pages: 150,
            seo: 400,
            design: 500,
            visualIdentity: 900,
            hosting: 150,
            maintenance: 1000,
            businessPlan: 700,
            mobileApp: 2500,
            support: { basic: 300, premium: 700 }
        };
    } catch (error) {
        throw new Error('Erreur lors de la récupération des prix du marché.');
    }
};

const styles = StyleSheet.create({
    page: { padding: 40, fontFamily: 'Roboto', fontSize: 12, lineHeight: 1.6 },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 40,
    },
    companyInfo: {
        fontSize: 12,
        fontWeight: 'bold',
        color: '#004aad',
    },
    clientInfo: {
        fontSize: 12,
        textAlign: 'right',
    },
    title: {
        fontSize: 18,
        fontWeight: 'bold',
        color: '#004aad',
        marginBottom: 20,
        textAlign: 'center',
    },
    table: {
        width: '100%',
        marginBottom: 40,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#e0e0e0',
    },
    tableHeader: {
        flexDirection: 'row',
        backgroundColor: '#004aad',
        color: '#fff',
        padding: 8,
    },
    tableRow: {
        flexDirection: 'row',
        borderBottomColor: '#e0e0e0',
        borderBottomWidth: 1,
        padding: 8,
    },
    tableColHeader: {
        width: '50%',
        fontWeight: 'bold',
        fontSize: 12,
    },
    tableColHeaderPrice: {
        width: '25%',
        fontWeight: 'bold',
        fontSize: 12,
        textAlign: 'right',
    },
    tableCol: {
        width: '50%',
        fontSize: 12,
    },
    tableColPrice: {
        width: '25%',
        fontSize: 12,
        textAlign: 'right',
    },
    footer: {
        fontSize: 10,
        marginBottom: 10,
    },
    footerText: {
        fontSize: 10,
        marginBottom: 10,
    },
    savingsText: {
        color: 'green',
        fontSize: 10,
        textAlign: 'left',
    },
});

const Result = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [totals, setTotals] = useState({ totalHT: 0, tva: 0, totalTTC: 0 });
    const [marketTotals, setMarketTotals] = useState({ totalHT: 0, tva: 0, totalTTC: 0 });
    const [services, setServices] = useState([]);
    const [alerts, setAlerts] = useState([]);

    const responses = location.state || JSON.parse(localStorage.getItem('quizResponses'));

    // Détection de l'utilisateur sur mobile
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    useEffect(() => {
        // Validation stricte du champ "nombre de pages"
        if (!responses || !responses.email || !responses.siteType || !responses.pages || isNaN(responses.pages) || responses.pages <= 0) {
            setAlerts(prevAlerts => [...prevAlerts, 'Veuillez remplir correctement le champ "nombre de pages".']);
            return navigate('/');  // Empêche la navigation si le champ "nombre de pages" est incorrect
        }

        const fetchData = async () => {
            try {
                const marketPrices = await fetchMarketPrices();
                const newServices = calculatePrice(marketPrices, responses);
                setServices(newServices.services);
                setTotals(newServices.totals);
                setMarketTotals(newServices.marketTotals);
            } catch (error) {
                setAlerts(prevAlerts => [...prevAlerts, 'Erreur lors de la récupération des prix du marché.']);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [responses, navigate]);

    const calculatePrice = (marketPrices, responses) => {
        let totalHT = 0;
        let marketTotalHT = 0;
        const services = [];
        const discountFactor = 0.65;

        if (responses.siteType) {
            const siteTypePrice = marketPrices.siteType[responses.siteType] || 0;
            const basePrice = siteTypePrice * discountFactor;
            services.push({
                name: `Site: ${responses.siteType}`,
                price: basePrice,
                marketPrice: siteTypePrice
            });
            totalHT += basePrice;
            marketTotalHT += siteTypePrice;
        }

        if (responses.pages && !isNaN(responses.pages)) {
            const unitPrice = marketPrices.pages;
            const marketPrice = unitPrice * responses.pages;
            const basePrice = marketPrice * discountFactor;
            services.push({
                name: `Nombre de pages: ${responses.pages}`,
                unitPrice: unitPrice,
                price: basePrice,
                marketPrice: marketPrice
            });
            totalHT += basePrice;
            marketTotalHT += marketPrice;
        }

        ['seo', 'mobileApp', 'design', 'visualIdentity', 'hosting', 'maintenance', 'businessPlan'].forEach(option => {
            if (responses[option]) {
                const marketPrice = marketPrices[option] || 0;
                const basePrice = marketPrice * discountFactor;
                services.push({
                    name: questions.questions.find(q => q.name === option).question,
                    price: basePrice,
                    marketPrice: marketPrice
                });
                totalHT += basePrice;
                marketTotalHT += marketPrice;
            }
        });

        if (responses.support) {
            const supportType = marketPrices.support[responses.support] || 0;
            const basePrice = supportType * discountFactor;
            services.push({
                name: `Support: ${responses.support}`,
                price: basePrice,
                marketPrice: supportType
            });
            totalHT += basePrice;
            marketTotalHT += supportType;
        }

        if (responses.stack) {
            const stackPrice = marketPrices.stack?.[responses.stack] || 0;
            const basePrice = stackPrice * discountFactor;
            services.push({
                name: `Stack: ${responses.stack}`,
                price: basePrice,
                marketPrice: stackPrice
            });
            totalHT += basePrice;
            marketTotalHT += stackPrice;
        }

        const tva = totalHT * 0.2;
        const totalTTC = totalHT + tva;

        const marketTva = marketTotalHT * 0.2;
        const marketTotalTTC = marketTotalHT + marketTva;

        return { services, totals: { totalHT, tva, totalTTC }, marketTotals: { totalHT: marketTotalHT, tva: marketTva, totalTTC: marketTotalTTC } };
    };

    const generateReference = () => `DEV-${new Date().getTime()}`;

    const reference = generateReference();

    const handleDownloadClick = async () => {
        try {
            const pdfBlob = await pdf(PDFFile).toBlob();
            const fileName = `devis-${reference}`;

            // Pour mobile, on propose de télécharger sans afficher dans le navigateur
            const link = document.createElement('a');
            link.href = URL.createObjectURL(pdfBlob);
            link.setAttribute('download', `${fileName}.pdf`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            // Envoyer le PDF à Strapi
            const formData = new FormData();
            formData.append('files', pdfBlob, `${fileName}.pdf`);

            const uploadResponse = await axios.post('https://admin.studiocavalli.org/api/upload', formData, {
                headers: {
                    Authorization: 'Bearer 90f817c21cd0d637309f79ece94b4d77d28326c731069db17e60c0d6cb09afe5f72f45c1a535f69fa405ffcca24ae9fe05f04d414c8b886524bc684b0d4539415882f0311af1a49eeeb3afa0cf4b97be5ddd5de05d12c94b7db257765ca7ad5eac89d2810fe336dcbee41ca7f3729e8a96f3d28518317ee9fb7c4c1749efc761'
                }
            });

            const uploadedFile = uploadResponse.data[0];

            const devisData = {
                data: {
                    email: responses.email,
                    date: new Date().toISOString().split('T')[0],
                    file: uploadedFile.id,
                    reference: reference
                }
            };

            await axios.post('https://admin.studiocavalli.org/api/devis', devisData, {
                headers: {
                    Authorization: 'Bearer 90f817c21cd0d637309f79ece94b4d77d28326c731069db17e60c0d6cb09afe5f72f45c1a535f69fa405ffcca24ae9fe05f04d414c8b886524bc684b0d4539415882f0311af1a49eeeb3afa0cf4b97be5ddd5de05d12c94b7db257765ca7ad5eac89d2810fe336dcbee41ca7f3729e8a96f3d28518317ee9fb7c4c1749efc761'
                }
            });

            localStorage.removeItem('quizResponses');
            localStorage.removeItem('currentStep');

            navigate('/');
        } catch (error) {
            setAlerts(prevAlerts => [...prevAlerts, 'Erreur lors de la génération ou du téléchargement du PDF.']);
        }
    };

    const PDFFile = (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.header}>
                    <View style={styles.companyInfo}>
                        <Text>Studio Cavalli</Text>
                        <Text>1624 Lemon Street, Palermo, Sicily</Text>
                        <Text>Téléphone : 01 23 45 67 89</Text>
                        <Text>Email : contact@studiocavalli.org</Text>
                    </View>
                    <View style={styles.clientInfo}>
                        <Text>Email : {responses?.email}</Text>
                        <Text>Référence : {reference}</Text>
                        <Text>Date : {new Date().toLocaleDateString()}</Text>
                    </View>
                </View>
    
                <Text style={styles.title}>DÉTAIL DU DEVIS</Text>
    
                <View style={styles.table}>
                    <View style={styles.tableHeader}>
                        <Text style={styles.tableColHeader}>Service</Text>
                        <Text style={styles.tableColHeaderPrice}>PUHT (€)</Text>
                        <Text style={styles.tableColHeaderPrice}>Prix HT (€)</Text>
                        <Text style={styles.tableColHeaderPrice}>Prix marché (€)</Text>
                    </View>
    
                    {services.map((service, index) => (
                        <View key={index} style={styles.tableRow}>
                            <Text style={styles.tableCol}>{service.name}</Text>
                            <Text style={styles.tableColPrice}>
                                {service.unitPrice ? service.unitPrice.toFixed(2) : 'N/A'} €
                            </Text>
                            <Text style={styles.tableColPrice}>
                                {service.price !== undefined ? service.price.toFixed(2) : 'N/A'} €
                            </Text>
                            <Text style={styles.tableColPrice}>
                                {service.marketPrice !== undefined ? service.marketPrice.toFixed(2) : 'N/A'} €
                            </Text>
                        </View>
                    ))}
    
                    <View style={styles.tableRow}>
                        <Text style={styles.tableCol}>Total HT</Text>
                        <Text style={styles.tableColPrice}></Text>
                        <Text style={styles.tableColPrice}>
                            {totals.totalHT !== undefined ? totals.totalHT.toFixed(2) : 'N/A'} €
                        </Text>
                        <Text style={styles.tableColPrice}>
                            {marketTotals.totalHT !== undefined ? marketTotals.totalHT.toFixed(2) : 'N/A'} €
                        </Text>
                    </View>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableCol}>TVA (20%)</Text>
                        <Text style={styles.tableColPrice}></Text>
                        <Text style={styles.tableColPrice}>
                            {totals.tva !== undefined ? totals.tva.toFixed(2) : 'N/A'} €
                        </Text>
                        <Text style={styles.tableColPrice}>
                            {marketTotals.tva !== undefined ? marketTotals.tva.toFixed(2) : 'N/A'} €
                        </Text>
                    </View>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableCol}>Total TTC</Text>
                        <Text style={styles.tableColPrice}></Text>
                        <Text style={styles.tableColPrice}>
                            {totals.totalTTC !== undefined ? totals.totalTTC.toFixed(2) : 'N/A'} €
                        </Text>
                        <Text style={styles.tableColPrice}>
                            {marketTotals.totalTTC !== undefined ? marketTotals.totalTTC.toFixed(2) : 'N/A'} €
                        </Text>
                    </View>
                </View>
    
                <Text style={styles.savingsText}>
                    Pssst : En choisissant notre entreprise, vous avez une économie potentielle de {(marketTotals.totalTTC - totals.totalTTC).toFixed(2)} €.
                </Text>
            </Page>
    
            {/* Page des conditions générales */}
            <Page size="A4" style={styles.page}>
                <Text style={styles.title}>Conditions Générales de Vente, de Service et d'Utilisation</Text>
    
                <Text style={styles.footerText}><Text style={{ fontWeight: 'bold' }}>1. Objet du Contrat :</Text> Ce contrat a pour objet la définition des conditions dans lesquelles l'entreprise fournit des services à ses clients. Les présentes conditions s'appliquent à toute commande passée auprès de l'entreprise.</Text>
    
                <Text style={styles.footerText}><Text style={{ fontWeight: 'bold' }}>2. Commandes :</Text> Toute commande passée auprès de l'entreprise est ferme et définitive dès la réception du devis signé par le client, accompagné de l'acompte de 50%.</Text>
    
                <Text style={styles.footerText}><Text style={{ fontWeight: 'bold' }}>3. Acompte :</Text> Un acompte de 50% est requis pour démarrer le projet. Le solde sera dû à la réception des prestations, sauf conditions particulières.</Text>
    
                <Text style={styles.footerText}><Text style={{ fontWeight: 'bold' }}>4. Livraison :</Text> Le délai de livraison sera discuté en fonction du projet. L'entreprise s'engage à informer le client de tout retard éventuel.</Text>
    
                <Text style={styles.footerText}><Text style={{ fontWeight: 'bold' }}>5. Propriété Intellectuelle :</Text> L'ensemble des créations réalisées dans le cadre de ce contrat (maquettes, logiciels, sites web, applications, etc.) reste la propriété de l'entreprise jusqu'à paiement complet de toutes les sommes dues par le client.</Text>
    
                <Text style={styles.footerText}><Text style={{ fontWeight: 'bold' }}>6. Confidentialité :</Text> L'entreprise et le client s'engagent à garder strictement confidentielles toutes les informations échangées dans le cadre du projet. Cela comprend les informations techniques, commerciales, ainsi que les données personnelles.</Text>
    
                <Text style={styles.footerText}><Text style={{ fontWeight: 'bold' }}>7. Responsabilité :</Text> L'entreprise ne pourra être tenue responsable de tout préjudice indirect subi par le client, tel qu'une perte de chiffre d'affaires, de données ou de clientèle, sauf en cas de faute grave ou intentionnelle.</Text>
    
                <Text style={styles.footerText}><Text style={{ fontWeight: 'bold' }}>8. Force Majeure :</Text> En cas de force majeure (catastrophe naturelle, grève, pandémie, etc.), l'entreprise se réserve le droit de suspendre ou de résilier le contrat sans que le client puisse prétendre à une quelconque indemnisation.</Text>
    
                <Text style={styles.footerText}><Text style={{ fontWeight: 'bold' }}>9. Garanties et Maintenance :</Text> Les services fournis sont garantis contre les défauts techniques pendant une période de 3 mois suivant la livraison. Toute intervention supplémentaire ou mise à jour demandée par le client fera l'objet d'une nouvelle facturation.</Text>
    
                <Text style={styles.footerText}><Text style={{ fontWeight: 'bold' }}>10. Résiliation :</Text> En cas de non-respect des obligations par le client (paiement non effectué, absence de transmission d'informations nécessaires, etc.), l'entreprise se réserve le droit de résilier le contrat après mise en demeure.</Text>
    
                <Text style={styles.footerText}><Text style={{ fontWeight: 'bold' }}>11. Données Personnelles :</Text> L'entreprise s'engage à respecter la réglementation en vigueur en matière de protection des données personnelles, en conformité avec le RGPD.</Text>
    
                <Text style={styles.footerText}><Text style={{ fontWeight: 'bold' }}>12. Réclamations et Litiges :</Text> En cas de litige, le client devra formuler une réclamation écrite dans un délai de 30 jours suivant la survenance du litige. Si aucun accord amiable n'est trouvé, le litige sera soumis aux juridictions compétentes du lieu de résidence de l'entreprise.</Text>
    
                <Text style={styles.footerText}><Text style={{ fontWeight: 'bold' }}>13. Loi Applicable :</Text> Les présentes conditions générales de vente sont régies par le droit français. Tout différend relatif à l'interprétation ou à l'exécution des présentes sera soumis à la juridiction compétente.</Text>
    
                {/* Conditions spécifiques pour l'hébergement web */}
                {responses.hosting && (
                    <>
                        <Text style={styles.footerText}><Text style={{ fontWeight: 'bold' }}>14. Hébergement Web :</Text> Le paiement pour l'hébergement web se fait pour une période d'un an. L'hébergement est fourni par notre partenaire officiel, PrivateHeberg, qui sera votre interlocuteur direct au moment de la signature du contrat. En cas de problème lié à l'hébergement ou à l'accessibilité du site internet, il faudra se référer aux conditions générales de notre partenaire, PrivateHeberg, qui est l'unique responsable en matière d'hébergement.</Text>
                    </>
                )}
    
                {responses.support === 'premium' && (
                    <>
                        <Text style={styles.footerText}>
                            <Text style={{ fontWeight: 'bold' }}>14. Support Premium :</Text> En choisissant le support premium, vous bénéficiez d'un support technique avancé avec une disponibilité 24h/24, 7j/7, ainsi que des temps de réponse garantis sous 1 heure pour toute demande urgente.
                        </Text>
                        <Text style={styles.footerText}>
                            Ce support inclut également des interventions prioritaires pour la maintenance et les mises à jour critiques.
                        </Text>
                    </>
                )}
    
                {responses.support === 'basic' && (
                    <>
                        <Text style={styles.footerText}>
                            <Text style={{ fontWeight: 'bold' }}>14. Support Basique :</Text> Le support basique vous offre une assistance technique pendant les heures ouvrées avec un temps de réponse moyen de 24 heures pour les requêtes non urgentes.
                        </Text>
                        <Text style={styles.footerText}>
                            Toute intervention hors des heures ouvrées peut entraîner des frais supplémentaires.
                        </Text>
                    </>
                )}
            </Page>
        </Document>
    );
    

    return (
        <div className="bg-white w-full p-8 md:p-12 rounded-xl shadow-lg border border-gray-200 text-center">
            {loading ? (
                <p>Chargement des prix...</p>
            ) : (
                <>
                    <h2 className="text-3xl font-bold mb-6 text-gray-900">Votre devis est prêt !</h2>
                    <p className="mb-6 text-lg text-gray-700">
                        Total : {totals.totalTTC ? totals.totalTTC.toFixed(2) : '0.00'} € TTC
                    </p>
                    <p className="mb-6 text-lg text-green-600">
                        En choisissant notre entreprise, vous avez une économie potentielle de {(marketTotals.totalTTC - totals.totalTTC).toFixed(2)} €.
                    </p>

                    <button
                        className="bg-green-600 text-white py-3 px-8 rounded-lg transition-all duration-300 ease-in-out transform hover:scale-105"
                        onClick={handleDownloadClick}
                    >
                        Télécharger le devis
                    </button>
                </>
            )}

            <div className="fixed bottom-4 right-4 space-y-2 z-50">
                {alerts.map((alertMessage, index) => (
                    <Alert
                        key={index}
                        message={alertMessage}
                        onClose={() => setAlerts(alerts.filter((_, i) => i !== index))}
                    />
                ))}
            </div>
        </div>
    );
};

export default Result;